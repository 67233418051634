<template>
    <div class="container container-width">
        <div class="text-end">
            <Button class="button-color-lavender-purple mt-2 create-new-button" label="CREATE A TEAM" @click="showModal = true"
            v-if="isNavigationAllowed(NAVIGATIONCONST.CREATENEWTEAM)"/>
        </div>
            <DataTable class="mt-3" :value="teams" selectionMode="single" dataKey="id"
            :paginator="true" :rows="rows" :rowsPerPageOptions="rowPerPageOptions" :loading="loading"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            responsiveLayout="scroll" sortField="name" :sortOrder="sortOrder.Ascending">
                    <template #header>
                            <Button class="p-button-text" @click="displayFilter = true">
                                <img src="../../../../assets/filter-icon.png">
                                <span class="ms-2 color-lavender-purple">Filter</span>
                            </Button>
                     </template>
                <Column field="name" header="Name" sortable class="table-column-title">
                    <template #body="{ data }">
                        <span class="ms-0 legend-key" :class="data.state == teamState.Active ? 'light-green-background' :
                        'gray-background'"></span>
                        <a class="table-first-column" data-bs-toggle="tooltip" data-bs-placement="top"
                        :title="data.name" @click="onRowSelect(data)">
                            {{data.name}}
                        </a>
                    </template>
                </Column>
                <Column field="team_lead.name" header="Lead" sortable></Column>
                <Column field="members" header="Members" sortable>
                    <template #body="{ data }">
                        {{data.members?.length || 0}}
                    </template>
                </Column>
                <template #paginatorstart></template>
            </DataTable>
            <CreateTeamForm :teams="teams" @closeModal="(showModal = false, team = null)" :team="team" v-if="showModal"/>
            <TeamsFilter :displayFilter="displayFilter" @applyFilter="applyFilter" @closeFilter="displayFilter = false"/>
    </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import CreateTeamForm from '../create-teams/CreateTeamForm.vue';
import { toasterTime, rows, rowPerPageOptions, messages, tableIconColor, sortOrder } from '../../../../shared/constants/constants';
import { TeamState } from '../../../../shared/enums';
import { isNavigationAllowed } from '../../../../shared/utils/auth-utils';
import { NAVIGATIONCONST } from '../../../../shared/constants/navigation-constant';
import { FilterMatchMode } from 'primevue/api';
import TeamsFilter from './TeamsFilter.vue';
import { TeamService } from '../../../../apis';
export default {
    name: 'AllTeams',
    components: {
        CreateTeamForm,
        DataTable,
        Column,
        Button,
        TeamsFilter
    },
    data() {
        return {
            sortOrder,
            teams: [],
            rows: rows,
            rowPerPageOptions: rowPerPageOptions,
            team: null,
            loading: false,
            showModal: false,
            teamState: TeamState,
            tableIconColor: tableIconColor,
            NAVIGATIONCONST,
            isNavigationAllowed,
            displayFilter: false,
            filters: {
                'state': { value: null, matchMode: FilterMatchMode.EQUALS }
            }
        };
    },
    async created() {
        await this.fetchTeams();
    },
    methods: {
        async fetchTeams() {
            this.loading = true;
            try {
                this.teams = await TeamService.getSnapshot(this.filters);
            } catch (err) {
                this.$toast.add({ severity: 'error', closable: false,
                    detail: messages.teamValidation.teamsFetchingFailed, life: toasterTime });
            } finally {
                this.loading = false;
            }
        },
        onRowSelect(event) {
            if (isNavigationAllowed(NAVIGATIONCONST.UPDATETEAM)) {
                const data = _cloneDeep(event);
                this.team = data;
                this.showModal = !this.showModal;
            }
        },
        applyFilter(state) {
            this.filters.state.value=state;
            this.fetchTeams();
            this.displayFilter=false;
        }
    }
};
</script>
