<template>
    <Dialog :header="team ? 'Edit Team' : 'Create Team'" :visible="true" modal="true" :closable="false" :style="{ width: '70vw' }">
        <form>
            <div class="container-fluid mt-3 mb-5">
                <div class="row">
                    <div v-bind:class="team ? 'col-md-9' : 'col-md-12'">
                        <span>
                            <label for="teamName" class="fw-bold">Name<span class="text-danger ms-1">*</span></label>
                            <input
                                id="teamName"
                                class="w-100 form-control p-2"
                                type="text"
                                placeholder="Team Name"
                                v-model.lazy="v$.teamName.$model"
                            />
                        </span>
                        <span v-if="v$.teamName.$error && v$.teamName.required && v$.teamName.required.$invalid" class="text-danger"
                            >{{messages.teamValidation.teamNameErrorMessage}}
                        </span>
                        <span v-else-if="v$.teamName.$error && v$.teamName.maxLength && v$.teamName.maxLength.$invalid" class="text-danger"
                            >{{messages.teamValidation.teamNameCharacterLimit}}
                        </span>
                        <span
                            v-else-if="v$.teamName.$error && v$.teamName.asyncValidator && v$.teamName.asyncValidator.$invalid && teamName"
                            class="text-danger"
                            >{{messages.teamValidation.teamNameAlreadyExist}}
                        </span>
                    </div>
                    <div class="col-md-3" v-if="team">
                        <label class="fw-bold">Status</label>
                        <div>
                            <RadioButton id="active" name="active" :value="teamState.Active" v-model="form.state" />
                            <label for="active" class="ms-2 me-3 fw-bold">Active</label>
                            <RadioButton id="archived" name="archived" :value="teamState.Archived" v-model="form.state" />
                            <label for="archived" class="ms-2 fw-bold">Archived</label>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-11 col-sm-10">
                        <label for="TeamLead" class="fw-bold">Lead<span class="text-danger ms-1">*</span></label>
                        <InputText
                            id="TeamLead"
                            class="w-100"
                            type="text"
                            disabled="true"
                            placeholder="Team Lead"
                            v-model="v$.selectedTeamLead.$model"
                        />
                        <span
                            v-if="v$.selectedTeamLead.$error && v$.selectedTeamLead.required && v$.selectedTeamLead.required.$invalid"
                            class="text-danger"
                            >{{messages.teamValidation.teamLeadErrorMessage}}
                        </span>
                    </div>
                    <div class="col-md-1 col-sm-2" style="margin-top: 1.75rem">
                        <Button icon="pi pi-plus" id="plusButton" @click="showUserSelectionPopup(selectionType.single)" />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-12">
                        <label class="fw-bold">Members<span class="text-danger ms-1">*</span></label>
                        <Button icon="pi pi-plus" id="plusButton" @click="showUserSelectionPopup(selectionType.multiple)" />
                        <div class="members-div">
                            <p class="mb-1" v-for="(member, index) in selectedMembers" :key="index">
                                {{ member.name }}
                                <Button
                                    icon="pi pi-minus"
                                    @click="selectedMembers.splice(index, 1)"
                                    class="p-button-rounded p-button-danger p-button-text float-end"
                                    id="crossButton"
                                />
                            </p>
                            <span
                                v-if="v$.selectedMembers.$error && v$.selectedMembers.required && v$.selectedMembers.required.$invalid"
                                class="text-danger"
                                >{{messages.teamValidation.teamMemberRequired}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <template #footer class="mt-5">
            <hr />
            <Button
                label="DELETE"
                @click="deleteTeam(team.id)"
                class="p-button-danger float-start me-3"
                v-if="team && isNavigationAllowed(NAVIGATIONCONST.DELETETEAM)"
            />
            <Button
                type="submit"
                label="SAVE"
                @click="createTeam"
                :disabled="disableSaveButton"
                v-if="isNavigationAllowed(NAVIGATIONCONST.UPDATETEAM) || !team"
            />
            <Button label="CANCEL" @click="$emit('closeModal')" class="p-button-outlined me-3" />
        </template>
        <UserSelection
            :selectionMode="selectionMode"
            v-if="displayPopup"
            @selectionDone="selectionDone"
            @closePopup="displayPopup = false"
            :selectedMembers="this.selectedList"
            :listRole="memberListRole"
        />
    </Dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { maxLength, required, helpers } from '@vuelidate/validators';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Utility from '../../../../shared/utility/utility';
import RadioButton from 'primevue/radiobutton';
import { toasterTime, messages, userActivities, selectionType, toasterType } from '../../../../shared/constants/constants';
import { memberListRole } from '../../../../shared/constants/service-constants';
import { TeamState } from '../../../../shared/enums';
import UserSelection from '../../../../shared/popup/UserSelection.vue';
import { isNavigationAllowed } from '../../../../shared/utils/auth-utils';
import { NAVIGATIONCONST } from '../../../../shared/constants/navigation-constant';
import { TeamService } from '../../../../apis';
import { UserActivity } from '../../../../shared/utils';
const { withAsync } = helpers;
export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'CreateTeamForm',
    components: {
        Dialog,
        Button,
        InputText,
        RadioButton,
        UserSelection
    },
    props: {
        team: Object,
        teams: Object
    },
    data() {
        return {
            userActivities: userActivities,
            form: {
                id: this.team ? this.team.id : '',
                state: this.team ? this.team.state : TeamState.Active
            },
            teamName: this.team ? this.team.name : '',
            TeamLead: this.team ? this.team.team_lead : null,
            selectedTeamLead: this.team ? this.team.team_lead.name : '',
            selectedTeamLeadId: this.team ? this.team.team_lead.id : '',
            selectedMembers: this.team ? this.team.members : [],
            teamState: TeamState,
            displayPopup: false,
            selectionMode: null,
            selectedList: null,
            disableSaveButton: false,
            teamNames: [],
            isNavigationAllowed,
            NAVIGATIONCONST,
            memberListRole: memberListRole,
            messages,
            selectionType
        };
    },
    validations() {
        return {
            teamName: { required, maxLength: maxLength(100), asyncValidator: withAsync(this.checkTeamNameExist) },
            selectedTeamLead: { required },
            selectedMembers: { required }
        };
    },
    methods: {
        showToaster(message, type) {
            this.$toast.add({
                severity: type,
                closable: false,
                detail: message,
                life: toasterTime
            });
        },
        async checkTeamNameExist() {
            if (this.teamNames.length === 0) {
                this.teams.forEach(team => {
                    this.teamNames.push({
                        id: team.id,
                        name: team.name.toLowerCase()
                    });
                });
            }
            if (this.teamName) {
                const tempTeamName = Utility.removeInBetweenSpaces(this.teamName).trim();
                const existingTeam = this.teamNames.find(x => x.name == tempTeamName && x.id != this.form.id);
                if (existingTeam) {
                    return false;
                } else if (!existingTeam) {
                    const value = await TeamService.checkTeamExist(tempTeamName, this.team ? this.form.id : 0);
                    return value ? false : true;
                }
            }
            return false;
        },
        async deleteTeam(id) {
            this.$confirm.require({
                message: messages.teamValidation.deleteConfirmation.message,
                header: messages.teamValidation.deleteConfirmation.header,
                accept: async () => {
                    try {
                        await TeamService.delete(id);
                        this.showToaster(messages.teamValidation.teamDeleteSuccess, toasterType.success);
                        this.$emit('closeModal');
                    } catch (err) {
                        this.showToaster(messages.teamValidation.teamDeleteFailed, toasterType.error);
                    }
                },
                acceptClass: 'delete-popup-accept-btn',
                rejectClass: 'p-button-outlined'
            });
        },
        async createTeam() {
            this.disableSaveButton = true;
            const isFormValid = await this.v$.$validate();
            if (!isFormValid) {
                this.disableSaveButton = false;
                return;
            }
            const data = {
                name: Utility.removeInBetweenSpaces(this.teamName).trim(),
                team_lead: {
                    id: this.selectedTeamLeadId,
                    name: this.selectedTeamLead
                },
                members: this.selectedMembers,
                state: this.form.state
            };
            if (!this.team) {
                const createData = {
                    ...data,
                    info: {
                        ...UserActivity.getActivity(userActivities.created),
                        ...UserActivity.getActivity(userActivities.updated)
                    }
                };
                try {
                    await TeamService.create(createData);
                    this.showToaster(messages.teamValidation.teamCretedSuccess, toasterType.success);
                    this.$emit('closeModal');
                } catch (err) {
                    this.disableSaveButton = false;
                    this.showToaster(messages.teamValidation.teamCreateFailed, toasterType.error);
                }
            } else {
                const editData = {
                    ...data,
                    info: {
                        ...UserActivity.getActivity(userActivities.updated),
                        ...UserActivity.getActivity(userActivities.archived, this.form.state)
                    }
                };
                try {
                    await TeamService.update(this.form.id, editData);
                    this.showToaster(messages.teamValidation.teamUpdateSuccess, toasterType.success);
                    this.$emit('closeModal');
                } catch (err) {
                    this.disableSaveButton = false;
                    this.showToaster(messages.teamValidation.teamUpdateFailed, toasterType.error);
                }
            }
        },
        showUserSelectionPopup(selectionMode) {
            this.selectionMode = selectionMode;
            if (selectionMode == selectionType.multiple) {
                this.selectedList = this.selectedMembers;
            } else {
                this.selectedList = this.TeamLead;
            }
            this.displayPopup = true;
        },
        selectionDone(data) {
            this.displayPopup = false;
            if (this.selectionMode == 'single') {
                this.selectedTeamLeadId = null;
                this.selectedTeamLead = null;
                this.TeamLead = null;
                if (data != null) {
                    this.selectedTeamLeadId = data.id;
                    this.selectedTeamLead = data.name;
                    const teamLead = {
                        id: data.id,
                        name: data.name
                    };
                    this.TeamLead = teamLead;
                }
            } else {
                this.selectedMembers = [];
                Object.keys(data).forEach(key => {
                    const obj = {
                        id: data[key].id,
                        name: data[key].name
                    };
                    this.selectedMembers.push(obj);
                });
            }
            this.selectedList = null;
        }
    },
    emit: ['closeModal']
};
</script>

<style scoped>
#plusButton {
    width: 2.813rem;
    float: right;
}
#crossButton {
    height: 1.688rem;
}
.members-div {
    max-height: 9.375rem;
    overflow: auto;
    max-width: 57.5rem;
}
</style>
